<template>
  <div class="map">
    <div class="scrollview" ref="map">
      <div class="interactive-map">
        <img src="@/assets/images/map.png" class="map-image">

        <div v-bind:class="{ bridge: true, nearby: isNearby(bridge.location) }" v-for="bridge in getBridges()" :key="bridge.teken" :style="getPosition(bridge.location)" @click="bridge.infoPage ? goToInfoPage(bridge) : undefined">{{bridge.teken}}</div>
        <div v-bind:class="{ muur: true, nearby: isNearby(muur.location) }" v-for="muur in getMuren()" :key="muur.teken" :style="getPosition(muur.location)" @click="muur.infoPage ? goToInfoPage(muur) : undefined">{{muur.teken}}</div>

        <img src="@/assets/images/marker.png" class="myMarker" :style="markerLocation">
      </div>

      <a href="https://thebridges.be/downloads/map.jpg" target="_blank" class="btnDownloadMap">Download Map</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Map',
  data() {
    return {
      locationWatcher: undefined,
      start: { x: 3.189788, y: 51.230159 },
      end: { x: 3.254288, y: 51.186766 },
      location: { lat: 0, lng: 0 },
      gotCentered: false // is voor opstart van deze pagina, om kaart te centeren naar positie user
    }
  },
  computed: {
    markerLocation() {
      var percentage = {
        x: ((this.location.lng - this.start.x) / (this.end.x - this.start.x)) * 100,
        y: ((this.start.y - this.location.lat) / (this.start.y - this.end.y)) * 100
      }
      
      return `left: ${percentage.x}%; top: ${percentage.y}%`;
    }
  },
  methods: {
    ...mapGetters(["getBridges", "getMuren"]),
    setupLocationTracker() {
      this.locationWatcher = navigator.geolocation.watchPosition((loc) => {
        this.location = { lat: loc.coords.latitude, lng: loc.coords.longitude };

        if (!this.gotCentered) {
          this.centerMe(this.location);
        }
      }, () => {}, {enableHighAccuracy: true})
    },
    getPosition(location) {
      var percentage = {
        x: ((location.lng - this.start.x) / (this.end.x - this.start.x)) * 100,
        y: ((this.start.y - location.lat) / (this.start.y - this.end.y)) * 100
      }

      return `left: ${percentage.x}%; top: ${percentage.y}%`;
    },
    isNearby(loc) {
      const radius = { x: 0.005, y: 0.003 };

      if (loc.lat > (this.location.lat - radius.x) && loc.lat < (this.location.lat + radius.x)) {
        if (loc.lng > (this.location.lng - radius.y) && loc.lng < (this.location.lng + radius.y)) {
          return true;
        }
      }

      return false;
    },
    goToInfoPage(poi) {
      var imageIndex = poi.imageIndex ? "&imageIndex=" + poi.imageIndex : "";

      this.$router.push({ path: `/info?name=${poi.infoPage}${imageIndex}` });
    },
    centerMe(location) {
      this.gotCentered = true;
      var x = 0, y = 0;

      var percentage = {
        x: ((location.lng - this.start.x) / (this.end.x - this.start.x)) * 100,
        y: ((this.start.y - location.lat) / (this.start.y - this.end.y)) * 100
      }

      if (window.innerWidth < 800) {
        x = ((percentage.x / 100) * 800) - (window.innerWidth / 2);
        y = ((percentage.y / 100) * 942) - (window.innerHeight / 2);
      }

      this.$refs.map.scrollBy(x, y);
    }
  },
  mounted() {
    this.setupLocationTracker();
    this.$refs.map.scrollBy(0, 60);
  },
  beforeDestroy() {
    navigator.geolocation.clearWatch(this.locationWatcher);
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/libs/_mixins.scss";
  @import "@/assets/sass/libs/_vars.scss";

  $sand: #fffbe8;
  $red: #E40605;
  $blue: #05019E;

  .map {
    flex: 1;
    width: 100%;
    height: 100%;
    max-height: 100%;
    position: relative;
    background-color: $sand;
  }

  .scrollview {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    max-height: calc(100vh - 60px);
    overflow: auto;
  }

  .interactive-map {
    position: relative;
    width: 800px;
    height: 882px;
    display: flex;
    margin: 60px auto;
    overflow: hidden;

    .map-image {
      width: 100%;
    }

    .muur, .bridge {
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      transform: translateX(-15px) translateY(-15px);

      display: flex;
      justify-content: center;
      align-items: center;

      font-family: "DIN-Condensed-Bold", sans-serif;
      font-weight: bolder;
      font-size: 20px;
      padding-top: 5px;

      transition-duration: .3s;

      cursor: pointer;

      &.nearby {
        width: 44px;
        height: 44px;
        border-radius: 22px;
        transform: translateX(-22px) translateY(-22px);
        font-size: 25px;
      }
    }

    .muur {
      background-color: $blue;
      color: white;
    }

    .bridge {
      background-color: #42a8df;
      color: $red;
    }

    .myMarker {
      position: absolute;
      width: 50px;
      height: 50px;
      transform: translateX(-25px) translateY(-25px);
      pointer-events: none;
    }
  }

  .btnDownloadMap {
    font-family: "DIN-Condensed-Bold", sans-serif;
    color: $blue;
    text-transform: uppercase;
    font-weight: bolder;
    text-decoration: underline;
    text-align: center;
    margin: auto;
    margin-bottom: 60px;
    width: 200px;
    display: none;

    @include tablet {
      display: block;
    }
  }
</style>